import React from 'react';
import MainLayout from '../layouts/main';
import { Typography } from '@material-ui/core';
import styled from '../styled';
import { SEO } from '../components/SEO';
import { DemoButton } from '../components/DemoButton';
import { Calendar } from 'react-feather';
import { Link } from 'gatsby';

const StyledLink = styled(Link)`
  color: ${(p) => p.theme.palette.primary.main};
  border-bottom: 1px solid;
`;

const Hr = styled('hr')`
  border: 1px solid #eee;
  margin: 48px auto;
  max-width: 300px;
`;

const Headline = styled(Typography)`
  @media (max-width: 1200px) {
    font-size: ${(p) => p.theme.typography.h5.fontSize}px;
    line-height: ${(p) => p.theme.typography.h5.lineHeight};
    margin: 0 auto ${(p) => p.theme.spacing(2)}px;
  }
`;

const Grid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${(p) => p.theme.spacing(3)}px;
  max-width: 900px;
  align-items: center;
  margin: 150px auto 56px;
  padding: ${(p) => p.theme.spacing(6)}px ${(p) => p.theme.spacing(4)}px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: ${(p) => p.theme.shape.borderRadius}px;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;

export default () => {
  return (
    <MainLayout>
      <div>
        <SEO
          pathname="/demo/"
          title="Schedule a demo"
          description="Schedule a demo with Affilimate to see how our affiliate analytics platform can help you grow your commerce content revenue."
        />
        <Grid>
          <div>
            <Headline
              variant="h4"
              component="h1"
              style={{ fontWeight: 900 }}
              paragraph
            >
              Talk to our team
            </Headline>
            <Typography variant="body1" component="p" paragraph>
              Get a demo of the platform and find out how Affilimate can help
              your media company grow its affiliate revenue line.
            </Typography>
            <br />
            <DemoButton
              variant="contained"
              text={
                <>
                  <Calendar size={16} /> &nbsp; Pick a time
                </>
              }
            />
            <br />
            <br />
            <br />
            <Typography variant="caption" component="p" color="textSecondary">
              <strong>Before booking:</strong> Affilimate is designed to be used
              by media companies already generating affiliate revenue.
              Coupon-only, cashback, and pre-launch websites are not currently
              supported.
            </Typography>
          </div>
          <div style={{ textAlign: 'center' }}>
            <img
              src="/images/features/dashboard-integrations-top.png"
              alt="affiliate dashboard"
              style={{
                display: 'block',
                margin: '0 auto',
                width: '90%',
                maxWidth: '350px'
              }}
            />
          </div>
        </Grid>
      </div>
      <div
        style={{
          textAlign: 'center',
          maxWidth: '600px',
          margin: '0 auto',
          paddingBottom: '48px'
        }}
      >
        <Hr />
        <Typography variant="body2" component="p" color="textSecondary">
          <strong>Have a simple question?</strong> Get in touch through our{' '}
          <StyledLink to="/contact/">contact form</StyledLink>.
        </Typography>
      </div>
    </MainLayout>
  );
};
